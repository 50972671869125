import UserService from '../services/user.service'
import { history } from '../App'
import { setCookie } from '../utils/cookies/setCookie'
import { executeAction } from './global.actions'
import { addItems } from './shopping.actions'
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'
import { firebaseAuth } from '../utils/firebase'
import { CookieEnum } from '../enums/cookies'
import { deleteCookie } from '../utils/cookies/deleteCookie'

export const actionTypes = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_REQUEST',
  LOGIN_REQUEST: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  GET_CURRENT_USER: 'GET_CURRENT_USER',
  GET_CURRENT_USER_REQUEST: 'GET_CURRENT_USER_REQUEST',
  GET_CURRENT_USER_SUCCESS: 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_ERROR: 'GET_CURRENT_USER_ERROR',

  GET_USER_COMPANIES: 'GET_USER_COMPANIES',
  GET_USER_COMPANIES_REQUEST: 'GET_USER_COMPANIES_REQUEST',
  GET_USER_COMPANIES_SUCCESS: 'GET_USER_COMPANIES_SUCCESS',
  GET_USER_COMPANIES_ERROR: 'GET_USER_COMPANIES_ERROR',

  SAVE_CURRENT_COMPANY: 'SAVE_CURRENT_COMPANY',
  SAVE_CURRENT_MODULE: 'SAVE_CURRENT_MODULE',

  CREATE_NEW_USER: 'CREATE_NEW_USER',
  CREATE_NEW_USER_REQUEST: 'CREATE_NEW_USER_REQUEST',
  CREATE_NEW_USER_SUCCESS: 'CREATE_NEW_USER_SUCCESS',
  CREATE_NEW_USER_ERROR: 'CREATE_NEW_USER_ERROR',

  SEARCH_USER_BY_EMAIL: 'SEARCH_USER_BY_EMAIL',
  SEARCH_USER_BY_EMAIL_REQUEST: 'SEARCH_USER_BY_EMAIL_REQUEST',
  SEARCH_USER_BY_EMAIL_SUCCESS: 'SEARCH_USER_BY_EMAIL_SUCCESS',
  SEARCH_USER_BY_EMAIL_ERROR: 'SEARCH_USER_BY_EMAIL_ERROR',

  GET_USER_FIELDS: 'UPDATE_USER_FIELDS',
  GET_USER_FIELDS_REQUEST: 'GET_USER_FIELDS_REQUEST',
  GET_USER_FIELDS_SUCCESS: 'GET_USER_FIELDS_SUCCESS',
  GET_USER_FIELDS_ERROR: 'GET_USER_FIELDS_ERROR',

  UPDATE_USER_FIELDS: 'UPDATE_USER_FIELDS',
  UPDATE_USER_FIELDS_REQUEST: 'UPDATE_USER_FIELDS_REQUEST',
  UPDATE_USER_FIELDS_SUCCESS: 'UPDATE_USER_FIELDS_SUCCESS',
  UPDATE_USER_FIELDS_ERROR: 'UPDATE_USER_FIELDS_ERROR',

  RECOVER_PASSWORD_BY_EMAIL: 'RECOVER_PASSWORD_BY_EMAIL',
  RECOVER_PASSWORD_BY_EMAIL_REQUEST: 'RECOVER_PASSWORD_BY_EMAIL_REQUEST',
  RECOVER_PASSWORD_BY_EMAIL_SUCCESS: 'RECOVER_PASSWORD_BY_EMAIL_SUCCESS',
  RECOVER_PASSWORD_BY_EMAIL_ERROR: 'RECOVER_PASSWORD_BY_EMAIL_ERROR',

  GET_ALL_USERS_COTIZAP: 'GET_ALL_USERS_COTIZAP',

  GET_USERS_BY_USER: 'GET_USERS_BY_USER',

  GET_PROFILE: 'GET_PROFILE',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  SEND_EMAIL: 'SEND_EMAIL',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',

  CLEAR_COMPANY_STATE: 'CLEAR_COMPANY_STATE',
  UPDATE_USER: 'UPDATE_USER',
}

const recoverRequest = () => ({
  type: actionTypes.RECOVER_PASSWORD_BY_EMAIL_REQUEST,
})
const recoverSuccess = response => ({
  type: actionTypes.RECOVER_PASSWORD_BY_EMAIL_SUCCESS,
  response,
})
const recoverError = error => ({
  type: actionTypes.RECOVER_PASSWORD_BY_EMAIL_ERROR,
  error,
})

const getCurrentUserRequest = () => ({
  type: actionTypes.GET_CURRENT_USER_REQUEST,
})
const getCurrentUserSuccess = user => ({
  type: actionTypes.GET_CURRENT_USER_SUCCESS,
  user,
})
const getCurrentUserError = error => ({
  type: actionTypes.GET_CURRENT_USER_ERROR,
  error,
})

const getUserCompaniesRequest = () => ({
  type: actionTypes.GET_USER_COMPANIES_REQUEST,
})
const getUserCompaniesSuccess = companies => ({
  type: actionTypes.GET_USER_COMPANIES_SUCCESS,
  companies,
})
const getUserCompaniesError = error => ({
  type: actionTypes.GET_USER_COMPANIES_ERROR,
  error,
})

const clearPreviousCompanyState = company => ({
  type: actionTypes.CLEAR_COMPANY_STATE,
  company,
})
const saveCurrentCompanySuccess = company => ({
  type: actionTypes.SAVE_CURRENT_COMPANY,
  company,
})
const saveCurrentModuleSuccess = module => ({
  type: actionTypes.SAVE_CURRENT_MODULE,
  module,
})

const createNewUserRequest = () => ({
  type: actionTypes.CREATE_NEW_USER_REQUEST,
})
const createNewUserSuccess = response => ({
  type: actionTypes.CREATE_NEW_USER_SUCCESS,
  response,
})
const createNewUserError = error => ({
  type: actionTypes.CREATE_NEW_USER_ERROR,
  error,
})

const searchUserByEmailRequest = () => ({
  type: actionTypes.SEARCH_USER_BY_EMAIL_REQUEST,
})
const searchUserByEmailSuccess = response => ({
  type: actionTypes.SEARCH_USER_BY_EMAIL_SUCCESS,
  response,
})
const searchUserByEmailError = error => ({
  type: actionTypes.SEARCH_USER_BY_EMAIL_ERROR,
  error,
})

const getUserFieldsRequest = () => ({
  type: actionTypes.GET_USER_FIELDS_REQUEST,
})
const getUserFieldsSuccess = response => ({
  type: actionTypes.GET_USER_FIELDS_SUCCESS,
  response,
})
const getUserFieldsError = error => ({
  type: actionTypes.GET_USER_FIELDS_ERROR,
  error,
})

const updateUserFieldsRequest = () => ({
  type: actionTypes.UPDATE_USER_FIELDS_REQUEST,
})
const updateUserFieldsSuccess = response => ({
  type: actionTypes.UPDATE_USER_FIELDS_SUCCESS,
  response,
})
const updateUserFieldsError = error => ({
  type: actionTypes.UPDATE_USER_FIELDS_ERROR,
  error,
})

export const login = (email, password) => dispatch => {
  const process = async () => {
    const response = await signInWithEmailAndPassword(firebaseAuth, email, password)

    const token = await firebaseAuth.currentUser.getIdToken(false)
    setCookie(CookieEnum.userToken, token)

    history.push(`${process.env.PUBLIC_URL}/`)
    return response
  }
  dispatch(executeAction(actionTypes.LOGIN, process))
}

export const recoverPass = email => async dispatch => {
  dispatch(recoverRequest())
  try {
    await sendPasswordResetEmail(firebaseAuth, email)
    dispatch(
      recoverSuccess({
        msg: 'Se ha enviado un correo electrónico a su cuenta. Por favor sigue los pasos indicados.',
      }),
    )
  } catch (e) {
    dispatch(recoverError(e))
  }
}

export const getCurrentUser = () => async dispatch => {
  dispatch(getCurrentUserRequest())
  try {
    const user = await UserService.getCurrentUser()
    const userObject = JSON.stringify(user)
    setCookie(CookieEnum.currentUser, userObject)
    dispatch(getCurrentUserSuccess(user))
  } catch (error) {
    dispatch(getCurrentUserError(error))
    if (error.message && error.message === 'Network Error') {
      history.push('/mantenimiento')
    }
  }
}

export const getUserCompanies = () => async dispatch => {
  dispatch(getUserCompaniesRequest())
  try {
    const companies = await UserService.getCompanies()

    dispatch(getUserCompaniesSuccess(companies))
  } catch (error) {
    console.log('User companies', error)
    dispatch(getUserCompaniesError(error))
  }
}

export const logout = () => dispatch => {
  addItems([], true)
  deleteCookie(CookieEnum.userToken)

  dispatch(() => signOut(firebaseAuth))
}

export const saveCurrentCompany = company => async dispatch => {
  UserService.saveCurrentCompany(company)
  setCookie(CookieEnum.company, company)

  dispatch(clearPreviousCompanyState(company))
  dispatch(saveCurrentCompanySuccess(company))
}

export const saveCurrentModule = module => async dispatch => {
  UserService.saveCurrentModule(module)
  dispatch(saveCurrentModuleSuccess(module))
}

export const createNewUser = user => async dispatch => {
  dispatch(createNewUserRequest())
  try {
    const response = await UserService.createNewUser(user)
    dispatch(createNewUserSuccess(response))
  } catch (e) {
    console.error('Error: ', e.message)
    dispatch(createNewUserError(e))
  }
}

export const searchUserByEmail = email => async dispatch => {
  dispatch(searchUserByEmailRequest())
  try {
    const response = await UserService.searchUserByEmail(email)
    dispatch(searchUserByEmailSuccess(response))
  } catch (e) {
    console.error('Error', e.message)
    dispatch(searchUserByEmailError(e))
  }
}

export const getUserFields = id => async dispatch => {
  dispatch(getUserFieldsRequest())
  try {
    const response = await UserService.getUserFields(id)
    dispatch(getUserFieldsSuccess(response))
  } catch (e) {
    console.error('Error: ', e.message)
    dispatch(getUserFieldsError(e))
  }
}

export const updateUserFields = (id, fields) => async dispatch => {
  dispatch(updateUserFieldsRequest())
  try {
    const response = await UserService.updateUserFields(id, fields)
    dispatch(getUserFields(id))
    dispatch(updateUserFieldsSuccess(response))
  } catch (e) {
    console.error('Error: ', e.message)
    dispatch(updateUserFieldsError(e))
  }
}

export const getAllUsers = () => dispatch => {
  const process = async () => ({ balance: await UserService.getAdminUsers() })
  dispatch(executeAction(actionTypes.GET_GENERAL_BALANCE, process))
}

export const getUsersByUser = () => dispatch => {
  const process = async () => ({ users: await UserService.getUsersByUser() })
  dispatch(executeAction(actionTypes.GET_USERS_BY_USER, process))
}

export const getUserProfile = () => dispatch => {
  const process = async () => ({ profile: await UserService.getProfile() })
  dispatch(executeAction(actionTypes.GET_PROFILE, process))
}

export const updateUserProfile = request => dispatch => {
  const process = async () => ({
    profile: await UserService.updateProfile(request),
  })
  dispatch(executeAction(actionTypes.UPDATE_PROFILE, process))
}

export const sendConfirmEmail = () => dispatch => {
  const process = async () => ({ user: await UserService.sendConfirmEmail() })
  dispatch(executeAction(actionTypes.SEND_EMAIL, process))
}

export const confirmEmail = oobCode => dispatch => {
  const process = async () => ({
    profile: await UserService.confirmEmail(oobCode),
  })
  dispatch(executeAction(actionTypes.CONFIRM_EMAIL, process))
}

export const hideNotice = () => dispatch => {
  const process = async () => ({ user: await UserService.hideNotice() })
  dispatch(executeAction(actionTypes.GET_CURRENT_USER, process))
}

/**
 * Action to update user description
 * @param {number} userId User id to edit
 * @param {string} description New description
 */
export const updateDescription = (userId, description) => dispatch => {
  const process = async () => ({
    user: await UserService.updateDescription(userId, description),
  })
  dispatch(executeAction(actionTypes.UPDATE_USER, process))
}
