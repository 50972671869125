import { haveAnyValue } from 'src/utils/utilitiesV2'

/**
 * Get cookie by name
 * @param name
 */
function getCookieByName(name) {
  const cookies = document?.cookie?.split(';')

  const cookie = cookies.find(cookie => cookie.trim().startsWith(`${name}=`))
  if (haveAnyValue(cookie)) {
    return cookie.split('=')[1]
  }
  return null
}

export { getCookieByName }
