import React, { useEffect } from 'react'
import {
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  User,
  UserCredential,
} from 'firebase/auth'
import { createContext, useState } from 'react'
import { firebaseAuth } from 'src/utils/firebase'
import { getCookieByName } from '../../utils/cookies/getCookieByName'

interface FirebaseSession {
  loginUser: (email: string, password: string) => Promise<UserCredential>
  user: User | null | boolean
  isSignedIn: boolean
}

export const FirebaseContext = createContext<FirebaseSession | undefined>(undefined)

function FirebaseProvider({ children }: { children: React.ReactElement }) {
  const [user, setUser] = useState<User | null | false>(false)

  const isSignedIn = Boolean(user)

  const authTokenV2 = getCookieByName('customToken')

  const loginUser = (email: string, password: string) =>
    signInWithEmailAndPassword(firebaseAuth, email, password)

  useEffect(() => {
    const updateSession = async () => {
      const auth = await signInWithCustomToken(firebaseAuth, authTokenV2)
    }
    if (authTokenV2) {
      updateSession()
    }
  }, [authTokenV2])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, user => {
      if (user) {
        setUser(user)
        // loggedIn
      } else {
        setUser(null)
        // no valid
      }
    })

    return () => unsubscribe()
  }, [])

  return (
    <FirebaseContext.Provider value={{ loginUser, user, isSignedIn }}>
      {children}
    </FirebaseContext.Provider>
  )
}

export default FirebaseProvider
